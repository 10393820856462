import { createRouter, createWebHistory } from 'vue-router';
import AxaPassion from '@/views/AxaPassion.vue';
import Condition from '@/views/Condition.vue';
import Contact from '@/views/Contact.vue';
import FaqPage from '@/views/FaqPage.vue';
import FormContactSucces from '@/views/FormContactSucces.vue';
import FormQuotationSucces from '@/views/FormQuotationSucces.vue';
import Gift from '@/views/Gift.vue';
import Home from '@/views/Home.vue'; 
import HomeEN from '@/views/HomeEN.vue'; 
import HomeMobile from '@/views/HomeMobile.vue';
import HomeMobileEN from '@/views/HomeMobileEN';
import Moto from '@/views/Moto.vue'; 
import News from '@/views/News.vue';
import NewsList from '@/views/NewsList.vue';
import AdminSpace from '@/views/Admin.vue'; 
import Login from '@/components/Login.vue';
 
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Accueil - Symone'}
  },
  {
    path: '/m',
    name: 'HomeMobile',
    component: HomeMobile,
    meta: { title: 'Accueil - Symone'}
  },
  {
    path: '/en',
    name: 'HomeEN',
    component: HomeEN,
    meta: { title: 'Home - Symone' }
  },
  {
    path: '/m/en',
    name: 'HomeMobileEN',
    component: HomeMobileEN,
    meta: { title: 'Home - Symone'}
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminSpace,
    meta: { title: 'Admin - Symone', requiresAuth: true }
  },
  {
    path: '/moto',
    name: 'Moto',
    component: Moto,
    meta: { title: 'Moto - Symone', description: "Confiez à Symone vos trajets longue distance sur autoroutes. Vous et votre moto arriverez à destination dans les meilleures conditions pour profiter du plaisir de piloter là où le meilleur de votre passion peut s'exercer. Transport de motos seules sous conditions."},
    props: true
  },
  {
    path: '/axa',
    name: 'AxaPassion',
    component: AxaPassion,
    meta: { title: 'Offre partenaire AXA Passion - Symone' }
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList,
    meta: { title: 'Actus - Symone'}
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
    meta: { title: 'Actus - Symone'}
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact - Symone'},
    props: true
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: FaqPage,
    meta: { title: 'FAQ - Symone' }
  },
  {
    path: '/condition',
    name: 'Condition',
    component: Condition,
    meta: { title: 'Conditions de vente - Symone' },
    props: true
  },
  {
    path: '/gift',
    name: 'Gift',
    component: Gift,
    meta: { title: 'Carte cadeau - Symone' },
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login - Symone' },
  },
  {
    path: '/form/quotation/succes',
    name: 'FormQuotationSucces',
    component: FormQuotationSucces,
    meta: { title: 'Formulaire envoyé - Symone' },
  },
  {
    path: '/form/contact/succes',
    name: 'FormContactSucces',
    component: FormContactSucces,
    meta: { title: 'Formulaire envoyé - Symone'},
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async(to, from, next) => {
try {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (!token) {
      next('/login');
return;
    }
  } 

    next();
  } catch (error) {
    console.error('Failed to fetch version.json', error);
    next();
  }
});

export default router;