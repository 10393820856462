<template>
    <div class="news-page">
        <div class="nav" style="position: fixed; z-index: 2">
            <a href="/" target="_self"
            ><img
                src="../assets/images/ui/logo.png"
                style="max-width: 250px"
            /></a>
            <div class="menu details">
            <a href="/" target="_self">Accueil</a>
            <a href="/moto" target="_self">Symone à moto</a>
            <a href="/news" target="_self">Actus</a>
            <a href="/contact" target="_blank"
                >Contact</a
            >
            <a href="/faq" target="_blank">FAQ</a>
            <a href="/" target="_self"
                ><img
                src="../assets/images/ui/france.png"
                style="width: 25px; height: 25px"
            /></a>
            <a href="/en" target="_self"
                ><img
                src="../assets/images/ui/royaume-uni.png"
                style="width: 25px; height: 25px"
            /></a>
            </div>
        </div>
        <div class="list">
          
            <el-table v-if="displayedItems.length > 0" id='results' stripe :data="displayedItems" :default-sort="{ prop: 'date_publication', order: 'descending' }">
              <el-table-column label="" v-if="isWideScreen">
                    <template #default="{row}">
                      <a :href="`/news/${row.id}`" target="_blank">
                        <img
                          v-if="row.cover"
                          :src="`${config.API_BASE_URL}/assets/images/news/${row.cover}`"
                          alt=""
                          width='300'
                        />
                      </a>
                    </template>
                </el-table-column>
                <el-table-column label="Titre">
                    <template #default="{row}">
                      <a v-if="!isWideScreen" :href="`/news/${row.id}`" target="_blank">
                        <img
                          v-if="row.cover"
                          :src="`${config.API_BASE_URL}/assets/images/news/${row.cover}`"
                          alt=""
                          style="width: -webkit-fill-available;"
                        />
                        <br>
                      </a>
                        <a :href="`/news/${row.id}`" target="_blank" style="color: #000047; font-size: 20px; font-weight: 500; text-decoration: underline;">{{ row.title }}</a>
                    </template>
                </el-table-column>
                
                <el-table-column label="Publication" sortable  width='120px'>
                    <template #default="{row}">
                    {{ formattedDate(row.date_publication) }}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="Dernière modification">
                    <template #default="{row}">
                    {{ formattedDate(row.date_lastedit) }}
                    </template>
                </el-table-column> -->
            </el-table>
            <el-pagination background layout="prev, pager, next" :total="totalItems" :page-size="15" @current-change="handleCurrentChange" v-model:current-page="currentPage"/>
        </div>
        <div class="footer">
          <div style="margin: 50px">
            <div
              style="
                font-size: 20px;
                color: #e6007e;
                font-weight: 400;
                text-align-last: center;
              "
            >
              SUIVEZ-NOUS
              <br />
              <a href="https://fr.linkedin.com/company/symone" target="_blank"
                ><i
                  class="fab fa-linkedin"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://twitter.com/SymoneMobility" target="_blank"
                ><i
                  class="fab fa-twitter"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a
                href="https://www.instagram.com/symonemobility/?hl=fr"
                target="_blank"
                ><i
                  class="fab fa-instagram"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
                ><i
                  class="fab fa-facebook"
                  style="color: #000047; font-size: 20px"
                ></i
              ></a>
              <br />
              <br />
              NOUS CONTACTER
              <br />
              <span style="color: #000047"
                >contact@symone.fr<br />06 17 33 43 93</span
              >
              <br />
              <br />
              ÉCRIVEZ-NOUS
              <br />
              <a href="/contact" target="_blanc"
                ><span style="color: #000047">Contact</span></a
              >
            </div>
            <div
              style="
                font-size: 20px;
                color: #000047;
                font-weight: 400;
                text-align-last: center;
              "
            >
              <br />
              <a href="/" target="_blank">Bienvenue à bord</a>
              <br /><br />
              <a href="/moto" target="_blank">Symone à moto</a>
              <br /><br />
              <a href="/#home-tag-2" target="_blank"
                >Une nouvelle expérience de la route</a
              >
              <br /><br />
              <a
                href="/condition"
                target="_blank"
                >Conditions Générales de Vente</a
              >
              <br />
            </div>
          </div>
          <img src="@/assets/images/ui/logo_couleur (1).png" alt="Logo" />
        </div>
      </div>
  </template>
  
  <script>
  import axios from 'axios';
  import config from '@/config';

  export default {
    name: "NewsList",
    components: {
    },
    data() {
      return {
        config: config,
        news: [],
        currentPage: 1,
        collapseStatus: [],
        screenWidth: window.innerWidth
      };
    },
    created(){
      
      document.title= 'Actus - Symone'
    },
    computed: {
      totalItems() {
        return this.news.length;
      },
      displayedItems() {
        const startIndex = (this.currentPage - 1) * 15;
        const endIndex = startIndex + 15;
        return this.news.slice(startIndex, endIndex);
      },
      isWideScreen() {
        return this.screenWidth >= 768;
      }
    },
    mounted(){
      this.getNews();
      window.addEventListener('resize', this.handleResize);
    },
    methods: {
      async getNews(){
        try {
          let response = await axios.get(`${config.API_BASE_URL}/news/get/all`); // 替换为实际的后端API地址
          this.news = JSON.parse(JSON.stringify(response.data)); // 假设后端返回的数据格式为数组，包含所有新闻rows'
            this.news.reverse();
        } catch (error) {
          console.error('Error fetching news:', error);
        }
      },
      formattedDate(date){
        return String(date).slice(6, 8) + "-" + String(date).slice(4, 6) + "-" + String(date).slice(0, 4);
      },
      formatDate(dateString) {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6);
        const day = dateString.slice(6, 8);
        return `${day}/${month}/${year}`;
      },
      handleCurrentChange(page) {
        this.currentPage = page;
        this.collapseStatus = Array.from({ length: 15 }, () => false);
        this.$nextTick(() => {
          let targetElement = document.getElementById('results');
          if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      },
      handleResize() {
        this.screenWidth = window.innerWidth;
      }
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  };
  </script>
  <style lang="scss" scoped>
  *{
    text-decoration: none;
  }
  .list{
    padding: 100px 50px;
    text-align: center;
    text-align: -webkit-center;
    div:first-child{
      box-shadow: -10px -10px #4dffd78a, 10px 10px #ffffffad, -25px -3px #ff6394c7;
    }
  }
  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
.nav {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
  overflow-x: auto;
}
.nav::-webkit-scrollbar {
  display: none;
}
.nav::before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.details {
  max-width: 40vw;
  color: #dddddd;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.news-page{
    background-color: #000047;
  background-image: url("../assets/images/ui/home-5.png");
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
} 
.el-table--fit{
  max-width: 800px;
}
.el-table::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("../assets/images/ui/aile-couleur-transparant.png");
      z-index: 9;
      pointer-events: none; 
      background-position: bottom;
    }
.el-pagination{
  place-content: center;
  margin: 20px 0;
}
.footer {
  background-color: white;
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1316px) { 
  .footer img{
    width: 100%;
    max-width: 600px;
  }
}
</style>
  