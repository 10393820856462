<template>
    <div style="width: -webkit-fill-available;">
      <el-input type="text" @focus='onFocus' @blur="handleBlur" v-model="searchTerm" @input="handleInput" :placeholder="placeholder" :required="required" :disabled="disabled"/>
      <ul v-if="suggestions.length">
        <li v-for="suggestion in suggestions" :key="suggestion.place_id" @click="selectCity(suggestion)">
          {{ suggestion.display_name }}
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: "AutoComplete",
    props: {
      modelValue: String,
      placeholder: String,
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        searchTerm: this.modelValue,
        suggestions: [],
        selectedSuggestion: false,
      typingTimer: null,
      doneTypingInterval: 500
      };
    },
    watch:{
      modelValue(value){
        if(value == '') this.$emit('update:modelValue', '');
      }
    },
    
    methods: {
    async handleInput() {
      clearTimeout(this.typingTimer);
      if (this.searchTerm.length > 2) {
        this.typingTimer = setTimeout(async () => {
          await this.getSuggestions();
        }, this.doneTypingInterval);
      } else {
        this.suggestions = [];
      }
    },
      async getSuggestions() {
        if (this.searchTerm.length > 2) {
          const datas = await searchCity(this.searchTerm);
          const uniqueCities = [];
          
          datas.forEach(city => {
            if (!uniqueCities.some(uniqueCity => uniqueCity.display_name === city.display_name)) {
              uniqueCities.push(city);
            }
          });
          
          this.suggestions = uniqueCities.map(city => ({
            place_id: city.place_id,
            display_name: `${city.display_name}`
          }));

          if(this.searchTerm.toLowerCase().includes("lyon")){
            this.suggestions.unshift({display_name: 'Lyon Sud, France'});
            this.suggestions.unshift({display_name: 'Lyon Nord, France'});
          }
        } else {
          this.suggestions = [];
        }
      },
      selectCity(city) {
        this.searchTerm = city.display_name;
        this.suggestions = [];
        this.selectedSuggestion = true;
        this.$emit('update:modelValue', this.searchTerm);
      },
      onFocus(){
        this.selectedSuggestion = false;
      },
      handleBlur() {
        if (!this.selectedSuggestion) {
          this.searchTerm = '';
          this.$emit('update:modelValue', '');
        }
        this.selectedSuggestion = false; 
      }
    }
  };
  
  const searchCity = async (query) => {
    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${query}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`);
    const data = await response.json();
    return data;
};
  </script>
  
  <style scoped>
  ul {
    top: 30px;
    position: absolute;
    list-style-type: none;
    padding: 0;
    z-index: 3;
  }
  li {
    cursor: pointer;
    padding: 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
  }
  li:hover {
    background-color: #e0e0e0;
  }
  </style>