<template>
    <div>
      <div class="moto-bg">
        <div class="nav">
          <a href="/" target="_self"
            ><img
              src="../assets/images/ui/logo.png"
              style="margin-left: -150px; max-width: 250px"
          /></a>
          <div class="menu details">
            <a href="/" target="_self">Accueil</a>
            <a href="/moto" target="_blank">Symone à moto</a>
            <a href="/news" target="_blank">Actus</a>
            <a href="/contact" target="_blank"
              >Contact</a
            >
            <a href="/faq" target="_blank">FAQ</a>
            <a href="/" target="_blank"
              ><img
                src="../assets/images/ui/france.png"
                style="width: 25px; height: 25px"
            /></a>
            <a href="/en" target="_blank"
              ><img
                src="../assets/images/ui/royaume-uni.png"
                style="width: 25px; height: 25px"
            /></a>
          </div>
        </div>
          <div class="about1" style="text-align: -webkit-center; padding: 50px 50px 0 50px">
            <div class="title border">
              <span style="color: #e6007e">P</span>rofitez de votre temps, faites
              des économies
            </div>
            <div class="title border" style="font-size: 30px; margin-top: -10px">
              <span style="color: #e6007e">F</span>ini les contraintes à moto sur
              l'autoroute !
            </div>
            <br />
            <div class="banner flex">
              <div class="title" style="font-size: 35px; color: #2e2382; margin: 0; padding: 0 30px;">
                <b><span style="color: #e6007e">O</span>ffre spéciale club</b>
              </div>
              <img
                src="../assets/images/ui/axa_passion_logo_solid-blue.png"
                style="max-width: 200px"
              />
            </div>
            <div class="axa">
              <div
                class="description border"
                style="
                  color: white;
                  font-size: 20px;
                "
              >

    - Symone, en partenariat avec AXA Passion, vous propose <div class="highlight">20%*</div> de réduction sur votre demande de devis avec le code :
    <br/><br/>
    <div class="code">AXA20</div>
    <br/>
    N'attendez plus !
                <br />
                
                <a href="/moto?tab=tab-devis" target="_blank"
                  ><button
                    class='btn-rose'
                  >
                    J'EN PROFITE
                  </button></a
                >
                <br /><br />

                
                <figure class="wp-block-video" style="width: 100%">
                  <video
                    id="videoSymoneMoto"
                    style="border-radius: 10px; max-height: 50vh; max-width: 100%;"
                    src="../assets/videos/Publicite-Symone-moto-V12.mp4"
                    autoplay="autoplay"
                    loop="loop"
                    muted=""
                    controls="controls"
                  ></video>
                </figure>
                <br/>
                <i>* Offre valable jusqu'au 31/07/2024, non cumulable avec les autres codes promotionnels disponibles.</i>
                <br/><br/>
              </div>


              <div class="about3" style="text-align: -webkit-center;">
                  <img
                    src="../assets/images/axa-ex.jpg"
                    style="width:fit-content"
                  />
              </div>

            </div>
          </div>
        <div class="window">
          <div>
            <div class="about2">
              <div class="banner title" style="background-color: #000047; margin-top: -50px; padding: 100px 50px">
                <b><span style="color: #e6007e">S</span>ymone vous transporte avec
                votre moto aux portes de vos terrains de jeux</b>
              </div>
              <div
                style="
                  max-width: 1000px;
                  margin-top: 60px;
                  width: 100%;
                  height: fit-content;
                  display: flex;
                  align-items: center;
                "
              >
                <video
                  id="videoSymoneMoto"
                  style="border-radius: 10px; width: 40%"
                  src="../assets/videos/Publicite-Symone-moto-V12.mp4"
                  autoplay="autoplay"
                  loop="loop"
                  muted=""
                  controls="controls"
                ></video>
                <div style="width: 70%; margin: 20px">
                  <div
                    class="title border"
                    style="text-align-last: auto; font-size: 40px"
                  >
                    <span style="color: #e6007e">P</span>ratique
                  </div>
                  <div
                    class="title border"
                    style="text-align-last: auto; font-size: 20px; line-height: 1"
                  >
                    En amont du réseau autoroutier, votre moto est chargée et
                    amarrée en toute sécurité sur une remorque. Ensuite
                    installez-vous confortablement et profitez du voyage !
                  </div>
                </div>
              </div>
              <div
                style="
                  max-width: 1000px;
                  flex-direction: row-reverse;
                  margin-top: 60px;
                  width: 100%;
                  height: fit-content;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="../assets/images/symonemoto4-scaled-e1693995436192.jpg"
                  style="border-radius: 10px; max-width: 40%; max-height: 250px"
                />
                <div style="width: 65%; margin: 20px">
                  <div
                    class="title border"
                    style="font-size: 40px"
                  >
                    <span style="color: #e6007e">U</span>ne expérience inédite
                  </div>
                  <div
                    class="title border"
                    style="
                      font-size: 20px;
                      line-height: 1;
                    "
                  >
                    Vous voyagez dans un van tout confort conduit par un
                    professionnel. Vous arrivez à destination en toute sécurité,
                    de manière plus décarbonée et en profitant enfin de toutes ces
                    longues heures sur l'autoroute que vous passiez derrière le
                    guidon.
                  </div>
                </div>
              </div>
              <div
                style="
                  max-width: 1000px;
                  margin-top: 60px;
                  width: 100%;
                  height: fit-content;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  src="../assets/images/symonemoto1-scaled.jpg"
                  style="border-radius: 10px; max-width: 40%; max-height: 250px"
                />
                <div style="width: 65%; margin: 20px">
                  <div
                    class="title border"
                    style="text-align-last: auto; font-size: 40px"
                  >
                    <span style="color: #e6007e">U</span>n service pour tous
                  </div>
                  <div
                    class="title border"
                    style="text-align-last: auto; font-size: 20px; line-height: 1"
                  >
                    À l'arrivée, récupérez instantanément votre moto et
                    faites-vous plaisir sur les plus belles routes. Symone propose
                    ses services en journée et la nuit, pour les professionnels et
                    les particuliers. Votre moto est assurée pendant toute la
                    durée du trajet en Symone.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer full-screen-element">
      <div style="margin: 50px; width: -webkit-fill-available">
        
        <div
          style="
            display: flex;
            justify-content: space-evenly;
            width: 100%;
          "
        >
          <img
            src="@/assets/images/ui/logo_couleur (1).png"
            alt="Logo"
            style="width: 200px; height: fit-content; align-self: center"
          />

          <div
            style="
              font-size: 20px;
              color: #000047;
              font-weight: 400;
              text-align-last: center;
            "
          >
            <br />
            <a href="/" target="_blank">Bienvenue à bord</a>
            <br /><br />
            <a href="/moto" target="_blank">Symone à moto</a>
            <br /><br />
            <a href="/#home-tag-2" target="_blank"
              >Une nouvelle expérience de la route</a
            >
            <br /><br />
            <a
              href="/condition"
              target="_blank"
              >Conditions Générales de Vente</a
            >
            <br />
          </div>

          <div
            style="
              font-size: 20px;
              color: #e6007e;
              font-weight: 400;
              text-align-last: center;
            "
          >
            SUIVEZ-NOUS
            <br />
            <a href="https://fr.linkedin.com/company/symone" target="_blank"
              ><i
                class="fab fa-linkedin"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://twitter.com/SymoneMobility" target="_blank"
              ><i
                class="fab fa-twitter"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a
              href="https://www.instagram.com/symonemobility/?hl=fr"
              target="_blank"
              ><i
                class="fab fa-instagram"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
              ><i
                class="fab fa-facebook"
                style="color: #000047; font-size: 20px"
              ></i
            ></a>
            <br />
            <br />
            NOUS CONTACTER
            <br />
            <span style="color: #000047"
              >contact@symone.fr<br />06 17 33 43 93</span
            >
            <br />
            <br />
            ÉCRIVEZ-NOUS
            <br />
            <a href="/contact" target="_blanc"
              ><span style="color: #000047">Contact</span></a
            >
          </div>
        </div>
      </div>
    </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import axios from "axios";
  import config from "@/config";
  import { ElNotification } from 'element-plus'
  
  export default {
    name: "AxaPassion",
    components: {
    },
    data() {
      return {
        about: true,
        tagBg1:
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
        tagBg2:
          "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1",
        tagBg3:
          "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0",
        subMenuTrajectory: "color: #44bbc3",
        subMenuDevis: "color: white",
        currentSubMenuTrajectory: true,
        currentTab: 2,
        items: [],
        currentIndex: 0,
        currentTag: 0,
        slideIndex: 0,
        search: {
          depart: "",
          arrival: "",
          date: "",
        },
        formDevis: {
          type: "oneWay",
          depart_date: null,
          depart_city: "",
          arrival_date: null,
          arrival_city: "",
          moto_nb: 0,
          moto_model: "",
          distance: "",
          passenger_nb: 0,
          passenger_list: [],
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          code: "",
          how: "",
          commentary: "",
        },
        formReservation: {
          price: "",
          date_depart: null,
          depart: "",
          arrival: "",
          moto_nb: 0,
          moto_model: "",
          passenger_nb: 0,
          passenger_list: [],
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          code: "",
          how: "",
          commentary: "",
          id: "",
          title: "",
          trajectory: null
        },
        results: [],
        tmpResults: [],
        collapseStatus: [],
        currentPage: 1,
        pageSize: 15,
        isLoading: false,
        isDevisSending: false,
        isReservationSending: false,
        showReservationPopup: false,
        showHelpPopup: false,
        refreshAutocomplete: 0,
        rulesDevis:{
          type: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'change' },
          ],
          depart_date: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          arrival_date: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          moto_nb: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          moto_model: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          passenger_nb: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          firstname: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          lastname: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          email: [
            { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
          ],
          phone: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
        },
        rulesReservation:{
          price: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          date_depart: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          moto_nb: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          moto_model: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          passenger_nb: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          firstname: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          lastname: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
          email: [
            { required: true, message: 'Veuillez saisir une adresse E-mail valide', trigger: ['blur', 'change'] },
          ],
          phone: [
            { required: true, message: 'Ce champ est obligatoire', trigger: 'blur' },
          ],
        },
      }
    },
    async created(){
      document.title= 'Offre partenaire AXA Passion - Symone'
      await this.getResults();
      if(this.$route.query.searchOptionsByHome && this.$route.query.searchOptionsByHome.length > 0) {
        this.search.depart = this.$route.query.searchOptionsByHome[0]
        this.search.arrival = this.$route.query.searchOptionsByHome[1]
        this.searchCities();
        this.refreshAutocomplete++;
      }
    },
    computed: {
      totalItems() {
        return this.tmpResults.length;
      },
      displayedItems() {
        const startIndex = (this.currentPage - 1) * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        return this.tmpResults.slice(startIndex, endIndex);
      },
    },
    async mounted() {
      await this.getResults();
      const urlParams = new URLSearchParams(window.location.search);
      const aValue = urlParams.get("currentTab"); 
      if (aValue !== null) {
        this.switchTab(aValue);
      }
      const tabToOpen = urlParams.get('tab');
      if (tabToOpen == 'tab-devis') {
          const tab = document.querySelector(`#tab-devis`);
          if (tab) {
              tab.click();
              this.$nextTick(() => {
                let targetElement = document.getElementById('devis-title');
                if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
              });
          }
      }
    },
    methods: {
      openReservationPopup(row){
        this.formReservation = {
          price: "",
          date_depart: null,
          depart: "",
          arrival: "",
          moto_nb: 0,
          moto_model: "",
          passenger_nb: 0,
          passenger_list: [],
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          code: "",
          how: "",
          commentary: "",
          id: "",
          title: "",
          trajectory: null
        }
  
        if(row.type == 'event'){
          this.formReservation.price = `Tarif à calculer par notre service(Event: ${row.name})`;
          this.formReservation.title = 'Event - ' + row.name;
          this.formReservation.id = row.id;
        }
        else{
  
        this.formReservation.title = `${row.depart}${row.layover1 ? ' → ' + row.layover1 : ''}${row.layover2 ? ' → ' + row.layover2 : ''}${row.layover3 ? ' → ' + row.layover3 : ''}→${row.arrival}`
        this.formReservation.id = row.id;
        this.formReservation.date_depart = this.formatDate(row.date);
        this.formReservation.trajectory = row;
  
        const radios = document.querySelectorAll(`input[name="optionPrice-${row.id}"]`);
    
        for (const radio of radios) {
          if (radio.checked) {
            switch(radio.value){
              case '0-1':
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover1;
                if(/^\d+$/.test(row.price0_1)){
                  if(!row.discount_value) this.formReservation.price = row.price0_1 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price0_1) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price0_1) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_1}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price0_1;
                }
                break;
              case '0-2':
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover2;
                if(/^\d+$/.test(row.price0_2)){
                  if(!row.discount_value) this.formReservation.price = row.price0_2 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price0_2) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price0_2) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_2}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price0_2;
                }
                break;
              case '0-3':
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.layover3;
                if(/^\d+$/.test(row.price0_3)){
                  if(!row.discount_value) this.formReservation.price = row.price0_3 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price0_3) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price0_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_3}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price0_3;
                }
                break;
              case '0-4':
                this.formReservation.depart = row.depart;
                this.formReservation.arrival = row.arrival;
                if(/^\d+$/.test(row.price0_4)){
                  if(!row.discount_value) this.formReservation.price = row.price0_4 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price0_4) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price0_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price0_4}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price0_4;
                }
                break;
              case '1-2':
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.layover2;
                if(/^\d+$/.test(row.price1_2)){
                  if(!row.discount_value) this.formReservation.price = row.price1_2 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price1_2) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price1_2) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_2}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price1_2;
                }
                break;
              case '1-3':
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.layover3;
                if(/^\d+$/.test(row.price1_3)){
                  if(!row.discount_value) this.formReservation.price = row.price1_3 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price1_3) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price1_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_3}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price1_3;
                }
                break;
              case '1-4':
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.arrival;
                if(/^\d+$/.test(row.price1_4)){
                  if(!row.discount_value) this.formReservation.price = row.price1_4 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price1_4) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price1_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price1_4}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price1_4;
                }
                break;
              case '2-3':
                this.formReservation.depart = row.layover2;
                this.formReservation.arrival = row.layover3;
                if(/^\d+$/.test(row.price2_3)){
                  if(!row.discount_value) this.formReservation.price = row.price2_3 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price2_3) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price2_3) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price2_3}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price2_3;
                }
                break;
              case '2-4':
                this.formReservation.depart = row.layover1;
                this.formReservation.arrival = row.arrival;
                if(/^\d+$/.test(row.price2_4)){
                  if(!row.discount_value) this.formReservation.price = row.price2_4 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price2_4) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price2_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price2_4}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price2_4;
                }
                break;
              case '3-4':
                this.formReservation.depart = row.layover3;
                this.formReservation.arrival = row.arrival;
                if(/^\d+$/.test(row.price3_4)){
                  if(!row.discount_value) this.formReservation.price = row.price3_4 + '€';
                  else{
                    if(row.discount_type == '1') {
                      this.formReservation.price = parseInt(row.price3_4) * (1 - (parseInt(row.discount_value) / 100));
                      this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}%)`
                    }
                    else {
                      this.formReservation.price = parseInt(row.price3_4) - parseInt(row.discount_value);
                      this.formReservation.price += `€(${row.price3_4}€ -${row.discount_value}€)`
                    }
                  }
                }
                else{
                  this.formReservation.price = row.price3_4;
                }
                break;
              default:
                this.formReservation.price = 'à vérifier par notre service'
                break;
            }
            
            break;
          }
        }
      }
        this.refreshAutocomplete++;
        this.showReservationPopup = true;
      },
    
      async getResults() {
        try {
          let response = await axios.get(`${config.API_BASE_URL}/trajectory/get/results`);
          this.results = JSON.parse(JSON.stringify(response.data));
          this.tmpResults = JSON.parse(JSON.stringify(response.data));
        } catch (error) {
          console.error("Error results/get:", error);
        }
      },
  
      
      async searchCities() {
        try {
          this.currentSubMenuTrajectory = true;
          this.subMenuTrajectory = 'color: #44bbc3';
          this.subMenuDevis = 'color: white';
  
          this.$nextTick(() => {
              let targetElement = document.getElementById('results');
              targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
  
          this.isLoading = true;
  
          let tmp = JSON.parse(JSON.stringify(this.results));
  
          if(this.search.depart){
            let cityName = this.search.depart.split(',')[0].trim();
            let countryName = this.search.depart.split(',')[this.search.depart.split(',').length - 1].trim();
  
            const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
            const response = await fetch(geocodingApiUrl);
            if (!response.ok) {
              throw new Error('City not found');
            }
            const data = await response.json();
  
            if (data.length === 0) {
              throw new Error('City not found');
            }
  
            const { lat, lon } = data[0];
  
            const overpassQuery = `
                [out:json];
                (
                    node(around:100000,${lat},${lon})[place=city];
                    way(around:100000,${lat},${lon})[place=city];
                    relation(around:100000,${lat},${lon})[place=city];
                    node(around:100000,${lat},${lon})[place=town];
                    way(around:100000,${lat},${lon})[place=town];
                    relation(around:100000,${lat},${lon})[place=town];
                );
                out;
            `;
  
            const responsesearch = await axios.post('https://overpass-api.de/api/interpreter', overpassQuery);
  
            const cities = responsesearch.data.elements.map(element => ({
                id: element.id,
                name: element.tags.name
            }));
  
            tmp = tmp.filter(result => {
                if (!result.depart) {
                    return true;
                }
                return cities.some(city => 
                    city.name?.toLowerCase() === (result.depart?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover1?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover2?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover3?.split(',')[0] || '').toLowerCase()
                );
            });
          }
          if(this.search.arrival){
            let cityName = this.search.arrival.split(',')[0].trim();
            let countryName = this.search.arrival.split(',')[this.search.arrival.split(',').length - 1].trim();
  
            const geocodingApiUrl = `https://nominatim.openstreetmap.org/search?format=json&q=${cityName},${countryName}&accept-language=fr&&bounded=1&viewbox=-10.8544921875,35.817813158696616,33.57421875,70.29390560813168`;
            const response = await fetch(geocodingApiUrl);
            if (!response.ok) {
              throw new Error('City not found');
            }
            const data = await response.json();
  
            if (data.length === 0) {
              throw new Error('City not found');
            }
  
            const { lat, lon } = data[0];
  
            const overpassQuery = `
                [out:json];
                (
                    node(around:100000,${lat},${lon})[place=city];
                    way(around:100000,${lat},${lon})[place=city];
                    relation(around:100000,${lat},${lon})[place=city];
                    node(around:100000,${lat},${lon})[place=town];
                    way(around:100000,${lat},${lon})[place=town];
                    relation(around:100000,${lat},${lon})[place=town];
                );
                out;
            `;
  
            const responsesearch = await axios.post('https://overpass-api.de/api/interpreter', overpassQuery);
  
            const cities = responsesearch.data.elements.map(element => ({
                id: element.id,
                name: element.tags.name
            }));
  
            tmp = tmp.filter(result => {
                if (!result.depart) {
                    return true;
                }
                return cities.some(city => 
                    city.name?.toLowerCase() === (result.arrival?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover1?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover2?.split(',')[0] || '').toLowerCase() || 
                    city.name?.toLowerCase() === (result.layover3?.split(',')[0] || '').toLowerCase()
                );
            });
          }
          if(this.search.date){
            const targetDateObject = new Date(`${this.search.date.substring(0, 4)}-${this.search.date.substring(4, 6).padStart(2, '0')}-${this.search.date.substring(6, 8).padStart(2, '0')}`);
  
            const startDate = new Date(targetDateObject);
            startDate.setDate(startDate.getDate() - 10);
  
            const endDate = new Date(targetDateObject);
            endDate.setDate(endDate.getDate() + 10);
  
            tmp = tmp.filter(date => {
                const currentDate = new Date(date.date.substring(0, 4), parseInt(date.date.substring(4, 6)) - 1, date.date.substring(6, 8));
                return currentDate >= startDate && currentDate <= endDate;
            });
          }
        if(tmp.length == 0 || tmp.every(item => item.type === 'event')){
          this.currentSubMenuTrajectory = false;
          this.subMenuDevis = 'color: #44bbc3';
          this.subMenuTrajectory = 'color: white';
          
          this.$nextTick(() => {
            let targetElement = document.getElementById('devis-title');
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
        }else{
          if(tmp.filter(item => item.type === 'trajectory').length <= 5){
            let trajectoryCount = 0;
            let firstEventIndexBeforeTrajectory = -1;
            let lastEventIndexAfterTrajectory = -1;
  
            for (let i = 0; i < tmp.length; i++) {
                const item = tmp[i];
                if (item.type === 'trajectory') {
                    trajectoryCount++;
                }else { 
                  if (trajectoryCount === 0) {
                    firstEventIndexBeforeTrajectory = i;
                  }
                }
            }
            if (firstEventIndexBeforeTrajectory !== -1) {
              tmp = tmp.slice(firstEventIndexBeforeTrajectory);
            }
            tmp.reverse();
            trajectoryCount = 0;
            for (let i = 0; i < tmp.length; i++) {
                const item = tmp[i];
                if (item.type === 'trajectory') {
                    trajectoryCount++;
                }else { 
                  if (trajectoryCount === 0) {
                    lastEventIndexAfterTrajectory = i;
                  }
                }
            }
            
            if (lastEventIndexAfterTrajectory !== -1) {
              tmp = tmp.slice(0, lastEventIndexAfterTrajectory + 1);
            }
            tmp.reverse();
          }
          
          this.tmpResults = tmp;
          this.$nextTick(() => {
            let targetElement = document.getElementById('results');
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
        }
        this.isLoading = false;
      } catch (error) {
          console.error('Error fetching data:', error);
          return [];
      }
    },
  
  
      generatePassengerList() {
        if (this.formDevis.passenger_nb > 20) {
          this.formDevis.passenger_nb = 20;
        }
        this.formDevis.passenger_list = [];
        for (let i = 0; i < this.formDevis.passenger_nb; i++) {
          this.formDevis.passenger_list.push({
            firstname: "",
            lastname: "",
            email: "",
          });
        }
      },
      generatePassengerListForReservation() {
        if (this.formReservation.passenger_nb > 20) {
          this.formReservation.passenger_nb = 20;
        }
        this.formReservation.passenger_list = [];
        for (let i = 0; i < this.formReservation.passenger_nb; i++) {
          this.formReservation.passenger_list.push({
            firstname: "",
            lastname: "",
            email: "",
          });
        }
      },
      async getCityCoordinates(city, country) {
        try {
          const response = await fetch(
            `https://nominatim.openstreetmap.org/search?format=json&q=${city},${country}`
          );
          const data = await response.json();
  
          if (data.length > 0) {
            return {
              lat: parseFloat(data[0].lat),
              lon: parseFloat(data[0].lon),
            };
          } else {
            throw new Error("No data found for the given city and country.");
          }
        } catch (error) {
          console.error("Error occurred while fetching city coordinates:", error);
          return null;
        }
      },
      async getRouteDistance(city1, country1, city2, country2) {
        try {
          const coordinates1 = await this.getCityCoordinates(city1, country1);
          const coordinates2 = await this.getCityCoordinates(city2, country2);
  
          const response = await fetch(
            `https://router.project-osrm.org/route/v1/driving/${coordinates1.lon},${coordinates1.lat};${coordinates2.lon},${coordinates2.lat}?overview=false`
          );
          const data = await response.json();
  
          if (data.code === "Ok" && data.routes && data.routes.length > 0) {
            const distanceInMeters = data.routes[0].distance;
            const distanceInKilometers = distanceInMeters / 1000;
            return distanceInKilometers;
          } else {
            throw new Error(
              "Unable to find route or invalid response from server."
            );
          }
        } catch (error) {
          console.error("Error occurred while fetching route distance:", error);
          return null;
        }
      },
      async calculateDistance(str1, str2) {
        const city1 = str1.split(",")[0];
        const country1 = str1.split(",")[str1.split(",").length - 1];
        const city2 = str2.split(",")[0];
        const country2 = str2.split(",")[str1.split(",").length - 1];
  
        const distance = await this.getRouteDistance(
          city1,
          country1,
          city2,
          country2
        );
        if (distance !== null) {
          return `${distance.toFixed(2)}km`;
        } else {
          return "Failed to calculate route distance.";
        }
      },
      async sendReservation() {
        try {
          if (!this.$refs.ruleReservationRef) return;
          let check = await new Promise((resolve, reject) => {
            this.$refs.ruleReservationRef.validate((valid, fields) => {
              if (valid) {
                console.log('submit!');
                resolve(true);
              } else {
                console.log('error submit:' + reject, fields);
                resolve(false);
              }
            });
          });
          if(!check) return;
          this.isReservationSending = true;
          const response = await axios.post(
            `${config.API_BASE_URL}/mail/reservation`,
            this.formReservation
          );
          console.log(response.data);
          ElNotification({
            title: 'Success',
            message: 'Demande envoyée, nous vous répondrons dans les plus brefs délais.',
            type: 'success',
            position: 'bottom-right',
            duration: 0
          })
  
          this.isReservationSending = false;
          this.showReservationPopup = false;
        } catch (error) {
          console.error("Error Send/Devis:", error);
        }
      },
      async sendDevis() {
        try {
          if (!this.$refs.ruleDevisRef) return;
          let check = await new Promise((resolve, reject) => {
            this.$refs.ruleDevisRef.validate((valid, fields) => {
              if (valid) {
                console.log('submit!');
                resolve(true);
              } else {
                console.log('error submit:' + reject, fields);
                resolve(false);
              }
            });
          });
          if(!check) return;
          this.isDevisSending = true;
          if(this.formDevis.depart_city && this.formDevis.arrival_city){
          this.formDevis.distance = await this.calculateDistance(
            this.formDevis.depart_city,
            this.formDevis.arrival_city
          );}
          const response = await axios.post(
            `${config.API_BASE_URL}/mail/devis`,
            this.formDevis
          );
          console.log(response.data);
          ElNotification({
            title: 'Success',
            message: 'Demande envoyée, nous vous répondrons dans les plus brefs délais.',
            type: 'success',
            position: 'bottom-right',
            duration: 0
          })
          this.isDevisSending = false;
        } catch (error) {
          console.error("Error Send/Devis:", error);
        }
      },
      formatDate(dateString) {
        const year = dateString.slice(0, 4);
        const month = dateString.slice(4, 6).padStart(2, "0");
        const day = dateString.slice(6, 8).padStart(2, "0");
        return `${year}-${month}-${day}`;
      },
      fullDateFR(dateString){
        const months = [
          'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
          'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
        ];
  
        const daysOfWeek = [
            'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'
        ];
  
        const year = dateString.substring(0, 4);
        const monthIndex = parseInt(dateString.substring(4, 6)) - 1;
        const day = dateString.substring(6, 8);
  
        const date = new Date(year, monthIndex, day);
  
        const dayOfWeek = daysOfWeek[date.getDay()];
        const month = months[monthIndex];
  
        return `${dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)} ${day} ${month} ${year}`;
      },
      checkDate(dateString) {
          const today = new Date();
          const todayYear = today.getFullYear();
          const todayMonth = today.getMonth() + 1;
          const todayDay = today.getDate();
          
          const year = parseInt(dateString.substring(0, 4));
          const month = parseInt(dateString.substring(4, 6));
          const day = parseInt(dateString.substring(6, 8));
          const date = new Date(year, month - 1, day);
          
          return date > new Date(todayYear, todayMonth - 1, todayDay);
      },
      handleCurrentChange(page) {
        this.currentPage = page;
        this.collapseStatus = Array.from({ length: this.pageSize }, () => false);
        this.$nextTick(() => {
          let targetElement = document.getElementById('results');
          if(targetElement) targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        });
      },
      switchTab(tab) {
        this.currentTab = tab;
        if (tab == 1) {
          this.tagBg1 =
            "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
          this.tagBg2 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
          this.tagBg3 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        }
  
        if (tab == 2) {
          this.tagBg2 =
            "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
          this.tagBg3 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
          this.tagBg1 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        }
  
        if (tab == 3) {
          this.tagBg3 =
            "background-color: #1d2327; color: #44bbc3; font-size: 25px; z-index: 1";
          this.tagBg2 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
          this.tagBg1 =
            "background-color: rgb(211 211 211); color: #333333; vertical-align: bottom; padding: 5px; z-index: 0";
        }
      },
    },
  };
  </script>
  
  <style scoped type="scss">
  .banner{
    background-color: white;
    padding: 20px;
    margin: 0 -50px;
  }
  .description{
    background-color: #2e238287;
    padding: 20px;
    width: 100%;
  }
  .flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
  .w-full{
    width: 100%;
  }
  .highlight{
    background-color: #e6007e;
    color: white;
    padding: 5px;
    display: inline-block;
    border-radius: 5px;
  }
  .code{
    display: block;
    width: fit-content;
    padding: 10px;
    background-color: white;
    color: #000047;
    border: 3px solid #000047;
    border-radius: 10px;
    text-shadow: none;
    font-weight: 800;
    font-size: 30px;
  }
  
  .footer {
    background: white;
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .window {
    width: 100%;
    box-sizing: border-box;
  }

  .nav {
    padding-top: 25px;
    margin-bottom: 25px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: start;
    overflow-x: auto;
    img{
      filter: none;
    }
  }
  .nav::-webkit-scrollbar {
    display: none;
  }
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
  .axa{
    display: flex;
    max-width: 2000px;
    justify-content: center;
  }
  .about3 {
    background: #e1e3de;
  }

  .about2 {
    background: #ffffff4f;
    flex-direction: column;
    align-items: center;
    text-align-last: center;
    padding: 50px;
    text-align: -webkit-center;

  }


  .flex-col {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
  .border {
    text-shadow: -1px -1px 0 #000047, 1px -1px 0 #000047, -1px 1px 0 #000047,
      1px 1px 0 #000047;
  }
  .border-white {
    text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
      1px 1px 0 #ffffff;
  }
  .rose{
    color: #e6007e !important;
  }
      .btn-blue {
        margin-left: auto;
        font-size: 20px;
        cursor: pointer;
      }
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .material-symbols-outlined {
    font-size: 30px;
    color: dimgray;
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
  }
  .material-symbols-outlined:hover {
    cursor: default;
  }
  .btn-rose {
    border: none;
    background-color: #e6007e;
    color: white;
    border-radius: 5px;
    padding: 13px 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    font-size: 20px;
        cursor: pointer;
  }
  
  .btn-trans-white {
    font-family: "Roboto Condensed", sans-serif;
    background-color: transparent;
    color: white;
    font-size: 20px;
    margin-top: 20px;
    width: 30vw;
    border: 2px solid white;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.3s; /* 添加过渡效果 */
  }
  .btn-trans-white:hover {
    background-color: white; /* 悬浮时背景色变为白色 */
    color: black; /* 悬浮时文字变为透明 */
  }
  .moto-bg {
    background-position: bottom center; /* 垂直和水平居中 */
    background-image: url("../assets/images/photo_2024-03-01_15-47-58.jpg");
    height: 100vh;
    background-size: cover;
    font-family: "Roboto Condensed", sans-serif;
    overflow-y: auto;
    box-sizing: border-box;
    box-shadow: inset 5px 5px 200px 20px;
  }
  .title {
    color: white;
    font-size: 50px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 400;
    line-height: 55px;
    margin-bottom: 20px;
  }
  .details {
    max-width: 40vw;
    color: gray;
    font-size: 20px;
    font-family: "Roboto Condensed", sans-serif;
    font-weight: 300;
    text-transform: uppercase;
  }
  
  @media screen and (max-width: 1280px) {
    .nav{
      justify-content: left;
    }
    .nav img{
      margin-left: 10px !important;
    }
    .footer img{
      width: 100%;
      max-width: 600px;
    }
    .axa{
      flex-wrap: wrap;
    }
    .description, .about3{
      width: 100%;
      img{
        width: inherit !important;
      }
    }
  }
  @media screen and (max-width: 800px) {
    .footer > div > div{
      flex-wrap: wrap;
      img{
        width: 100% !important;
      }
    }
    .about2 > div{
      flex-wrap: wrap;
      justify-content: center;
    }
    .about2 > div > video, .about2 > div > div, .about2 > div > img{
      width: 100% !important;
      max-width: 100% !important;
      max-height: none !important;
    }
  }
  </style>
  