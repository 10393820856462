<template>
  <div>
    <h2>Login</h2>
    <form @submit.prevent="login">
      <label>Username:</label>
      <input type="text" v-model="username" required /><br /><br />
      <label>Password:</label>
      <input type="password" v-model="password" required /><br /><br />
      <button type="submit">Login</button>
    </form>
    <p v-if="errorMessage" style="color: red">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  name: "LoginPage",
  data() {
    return {
      config: config,
      username: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(`${this.config.API_BASE_URL}/login`, {
          username: this.username,
          password: this.password,
        });
        const token = response.data.token;
        localStorage.setItem("token", token);
        this.$router.push("/admin");
      } catch (error) {
        this.errorMessage = error.response.data.message;
      }
    },
  },
};
</script>
