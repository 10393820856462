import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

export default {
  install: (app) => {
    CookieConsent.run({
        guiOptions: {
          consentModal: {
            layout: 'box inline',
            position: 'bottom right',
          },
          preferencesModal: {
            layout: 'box',
          },
        },
      
        onFirstConsent: () => {
          console.log('onFirstAction fired');
        },
      
        onConsent: () => {
          console.log('onConsent fired ...');
        },
      
        onChange: () => {
          console.log('onChange fired ...');
        },
      
        categories: {
          necessary: {
            readOnly: true,
            enabled: true,
          },
          analytics: {
            autoClear: {
              cookies: [
                {
                  name: /^(_ga|_gid)/,
                },
              ],
            },
          },
          ads: {},
        },
      
        language: {
          default: 'fr',
      
          translations: {
            fr: {
              consentModal: {
                title: "Bonjour voyageur, c'est l'heure des cookies !",
                description:
                  'Notre site utilise des cookies essentiels pour garantir son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ces derniers ne seront définis qu’après consentement.',
                acceptAllBtn: 'Accepter',
                acceptNecessaryBtn: 'Rejeter'
              },
            },
          },
        },
      });
    app.config.globalProperties.$CC = CookieConsent;
  },
};
