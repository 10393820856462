<template>
  <div id="app">
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
      rel="stylesheet"
    />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200"
    />
    <!-- 顶部导航栏 -->
    <nav></nav>

    <!-- 主要内容区域 -->
    <main>
      <!-- 根据路由加载的组件将会显示在这里 -->
      <router-view></router-view>
    </main>

    <!-- 底部 footer -->
    <footer>
      <!-- 底部信息 -->
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="sass">
*
  margin: 0
  font-family: "Roboto Condensed", sans-serif
  position: relative

.material-symbols-outlined
  font-variation-settings: 'FILL 0', 'wght 400', 'GRAD 0', 'opsz 24'
</style>
